import { createSlice } from '@reduxjs/toolkit';
import { EStatusTable } from 'graphql/table/table';

export interface globalStateType {
    searchText: {
        order: string;
        table: string;
    };
    filterOrder: string;
    filterTable: EStatusTable;
    merchantFilterTable: string;
    orderId: string | null;
    seats: Record<string, Record<string, Record<string, number[]>>>;
    groups: Record<string, Record<string, string[]>>;
}

const initialState: globalStateType = {
    searchText: {
        order: '',
        table: '',
    },
    filterOrder: 'PICKUP,DINING',
    filterTable: EStatusTable.ALL,
    merchantFilterTable: 'SERVING,RESERVED,AVAILABLE',
    orderId: null,
    seats: {},
    groups: {},
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        updateSearch: (state, action) => {
            state.searchText = action.payload.searchText;
        },
        updateFilterOrder: (state, action) => {
            state.filterOrder = action.payload.filterOrder;
        },
        updateFilterTable: (state, action) => {
            state.filterTable = action.payload.filterTable;
        },
        updateMerchantFilterTable: (state, action) => {
            state.merchantFilterTable = action.payload.merchantFilterTable;
        },
        openOrderDetail: (state, action) => {
            state.orderId = action.payload.orderId;
        },
        updateSeatsByGroup: (
            state,
            action: {
                payload: {
                    tableId: number;
                    cartId: string;
                    group: string;
                    seats: number[];
                };
            },
        ) => {
            const { tableId, cartId, seats, group } = action.payload;
            if (!cartId) {
                return;
            }
            if (!state.seats) {
                state.seats = {};
            }
            if (!state.seats[tableId]) {
                state.seats[tableId] = {};
            }
            if (!state.seats[tableId][cartId]) {
                state.seats[tableId][cartId] = {};
            }
            state.seats[tableId][cartId][group] = [...seats];
        },
        updateSeatsByCart: (
            state,
            action: {
                payload: {
                    tableId: number;
                    cartId: string;
                    seats: Record<string, number[]>;
                };
            },
        ) => {
            const { tableId, cartId, seats } = action.payload;
            if (!cartId) {
                return;
            }
            if (!state.seats) {
                state.seats = {};
            }
            if (!state.seats[tableId]) {
                state.seats[tableId] = {};
            }
            if (!state.seats[tableId][cartId]) {
                state.seats[tableId][cartId] = {};
            }
            state.seats[tableId][cartId] = { ...seats };
        },
        updateSeatGroup: (
            state,
            action: {
                payload: {
                    tableId: number;
                    cartId: string;
                    groups: string[];
                };
            },
        ) => {
            const { tableId, cartId, groups } = action.payload;
            if (!cartId) {
                return;
            }
            if (!state.groups) {
                state.groups = {};
            }
            if (!state.groups[tableId]) {
                state.groups[tableId] = {};
            }
            state.groups[tableId][cartId] = groups;
        },
    },
});

export const {
    updateSearch,
    updateFilterOrder,
    updateFilterTable,
    updateMerchantFilterTable,
    openOrderDetail,
    updateSeatsByGroup,
    updateSeatsByCart,
    updateSeatGroup,
} = globalSlice.actions;

export default globalSlice.reducer;
